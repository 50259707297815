.button {
  position: absolute;
  right: 50px;
  top: 3px;
  z-index: 2;

  :global(.sidenavContainer) & {
    right: 8px;
  }
}
