.sidenavOption {
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  display: flex;
  padding: 10px;
  &:hover {
    background-color: var(--primary-light-grey);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    svg {
      fill: #999;
    }

    &:hover {
      background-color: transparent;
    }
  }

  .badge {
    position: relative;
    top: 5px;
    left: 5px;
    background-color: #0a858d;
    color: #fff;
    border-radius: 50%;

    .badgeInner {
      padding: 2.8;
      text-align: center;
      font-size: 11px;
    }
  }
}

.linkPost {
  button {
    border: none;
    background-color: transparent;
    padding: 0;
  }
}
