@use "src/styles/constants" as *;
@use "src/styles/mixins";

$breadCrumbFont: $fontPrimary;
$breadCrumbSize: $fontSize;

ul.breadcrumb {
  padding: 5px 0px;
  list-style: none;
  display: flex;
  overflow: hidden;
  flex: 1;
}
ul.breadcrumb li {
  font-family: $breadCrumbFont;
  font-size: $breadCrumbSize;
  color: var(--primary-grey);
  @include mixins.truncate;
}
ul.breadcrumb li + li:before {
  padding: 4px;
  color: var(--primary-grey);
  content: "\203A";
  font-size: $breadCrumbSize;
  font-weight: bold;
}
ul.breadcrumb li.active {
  color: var(--primary-green);
  font-weight: 600;
}
