.sidenav {
  &Container {
    display: flex;
    flex: 1;
    height: 100vh;
    position: relative;
    width: 86vw;
    z-index: 1;
  }
  &ContentWrapper {
    position: relative;
    z-index: 1;
    display: block;
    height: 100%;
    overflow-y: auto;
    flex: 1;
  }

  &Wrapper {
    position: relative;
    background-color: #fff;
    color: var(--primary-light-grey);
    border-left: 2px solid var(--primary-light-grey);
    visibility: visible;
    z-index: 2;
    display: block;
    outline: 0;
    box-sizing: border-box;
    overflow-y: auto;
    :global(i.icon) {
      color: #555;
    }
  }
}

.sideModalWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
}
