@mixin truncate($atLine: 1) {
  @if $atLine == 1 {
    text-overflow: ellipsis;

    /* Needed to make it work */
    overflow: hidden;
    white-space: nowrap;
    display: block;
  } @else {
    display: -webkit-box;
    -webkit-line-clamp: $atLine;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
