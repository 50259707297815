.wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: auto;
  padding: 5px 10px 5px 5px;
  width: 100%;
  min-height: 70%;
  margin-bottom: 30px;

  :global(.sidenavContentWrapper) & {
    overflow: visible;
  }
}
