@use "sass:color";

.user_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  padding-bottom: 40px;
  border: 2px solid #dddddd;
  border-radius: 2px;
  background-color: #ffffff;
  z-index: 9500;
  position: absolute;
  right: 46px;
  top: 100%;
  min-width: 250px;
  cursor: default;
  left: -250px;
  top: 0;
  box-shadow: -2px 2px 5px #ddd;

  &__photo {
    height: 95px;
    width: 95px;
    margin-bottom: 17px;
    border: 1px solid #dddddd;
    border-radius: 50%;
    background-color: #dfdfdf;
  }

  &__button {
    border: 2px solid #f1f0f0;
    padding: 10px 20px;
    border-radius: 8px;
    background-color: var(--primary-emerald-green);
    cursor: pointer;
    white-space: nowrap;
    color: white;
  }

  &__button__outline {
    padding: 10px 20px;
    color: var(--primary-green);
    font-weight: bolder;
    cursor: pointer;
    white-space: nowrap;
    background-color: transparent;
    text-align: center;
    border-radius: 8px;
    border: 1px solid var(--primary-green);
    margin: 1px 5px;

    &:hover {
      cursor: pointer;
      white-space: nowrap;
      transition: 0.4s;
      color: color.adjust(#a8cf45, $lightness: -10%, $space: hsl);
    }

    &__secondary {
      @extend .user_info__button__outline;
      padding: 8px 20px;
      display: block;
    }
  }

  &__text {
    line-height: 1.5em;
    color: var(--primary-dark-grey);
    text-align: center;

    &.truncate {
      overflow-x: hidden;
      width: 220px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:nth-of-type(1) {
      font-weight: bolder;
      font-size: 16px;
    }
  }
}

.userInfoWrapper {
  position: fixed;
  right: 0;
  z-index: 9500;
  width: 46px;
  height: 46px;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;

  .userIcon {
    &:hover {
      fill: var(--primary-emerald-green);
    }
  }

  .userIconSigla {
    color: var(--primary-dark-grey);
    font-size: 9px;
    position: absolute;
    bottom: 0;
    right: 5px;
  }

  &:global(.layout-icon) {
    height: 36px;
    right: 5px;
    position: absolute;

    :global(.sided-nav) & {
      display: none;
    }
  }
}
